import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import iView from "iview";

import "iview/dist/styles/iview.css";
import VueLazyload from 'vue-lazyload'
import { post,specialPost } from "./api/api";
Vue.config.productionTip = false;
Vue.prototype.$post = post;
Vue.prototype.$specialPost = specialPost;
// Vue.prototype.$base64 = function(t) {
//   return require("js-base64").Base64.encode(t);
// };
Vue.use(iView);
Vue.use(VueLazyload);
import MetaInfo from "vue-meta-info";
Vue.use(MetaInfo);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
