<template>
  <div id="app">
    <router-view></router-view>
    <!-- <Spin size="large" fix v-if="getSpinType"></Spin> -->
  </div>
</template>
<style lang="less">
@import "./assets/less/home";
// @import "./assets/less/wap";
@import "./assets/less/article";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.ivu-spin-fix {
  z-index: 1111111111!important;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import countDown from "./components/countDown";
export default {
  components: {
    countDown: countDown
  },
  computed: {
    ...mapGetters(["getConfig", "getSpinType"])
  },
  watch: {
    getConfig(val) {
      this.webJson = val;
      let link = document.querySelector("link[rel*='icon']");
      document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = this.webJson.ico;
    }
  },
  created() {
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.$router.push({
        path:"pc/home",
        query: { lang: 1 }
      })
    }else {
      this.$router.push({
        path:"wap/home",
        query: { lang: 1 }
      })
    }
    
  },
  mounted() {
    this.configFrom();
  },
  methods: {
    ...mapActions(["configFrom"]),
  }
};
</script>
